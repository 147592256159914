<template>
  <div>
    <router-link class="come-back" to="/admin/status_bill">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.params.id ? 'Изменение статуса законопроекта' : 'Создание статуса законопроекта' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="test" ref="creteСommissionUpdate">
        <div class="content-sb">
          <p>Короткое сообщение об изменении<span>*</span></p>
          <input required placeholder="Введите статус законопроекта" v-model="editData.title" type="text" name="title" class="input blue">
        </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/status_bill" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.params.id ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'CreateStatusBill',
  data () {
    console.log(this.$route.params.id)
    return {
      editData: {
        title: '',
        id: '',
        code: ''
      }
    }
  },
  watch: {
    '$store.getters.getDetailCommissionUpdateTypes' () {
      const dataFromServer = this.$store.getters.getDetailCommissionUpdateTypes
      this.editData = {
        title: dataFromServer.title,
        code: dataFromServer.code,
        id: dataFromServer.id
      }
      this.$forceUpdate()
    }
  },
  methods: {
    test () {
      const formData = new FormData(this.$refs.creteСommissionUpdate)
      if (this.$route.params.id) {
        this.$store.dispatch('editCommissionUpdateTypes', { id: this.editData.id, title: this.editData.title })
      } else {
        this.$store.dispatch('createCommissionUpdateTypes', formData)
      }
    }
  },
  mounted () {
    this.$store.dispatch('setDetailTypes', this.$route.params.id)
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
